import React from 'react';
import {Box, Container, Grid, Theme, Toolbar, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";

const createStyles = (theme: Theme) => ({
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '11rem',
        borderTop: '1px solid ' + theme.palette.divider,
        backgroundColor: theme.palette.secondary.light,
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            height: '22rem',
        }
    },
    contactGrid: {
        marginBottom: theme.spacing(2)
    },
    contactTitle: {
        padding: theme.spacing(1)
    },
    copyright: {
        marginTop: theme.spacing(2)
    }
});

const Footer = () => {

    const styles = createStyles(useTheme());

    const {t} = useTranslation();

    return (
        <Box component="footer" sx={styles.footer}>
            <Container maxWidth="xl">
                <Toolbar>
                    <Grid container>
                        <Grid container
                              sx={styles.contactGrid}
                              direction="column"
                              alignItems="center"
                              xs={12} sm={4}
                        >
                            <Typography variant="h4" sx={styles.contactTitle}>
                                {t('footer_email')}
                            </Typography>
                            <Typography variant="body1">
                                office@eproxi.pl
                            </Typography>
                        </Grid>
                        <Grid container
                              sx={styles.contactGrid}
                              direction="column"
                              alignItems="center"
                              xs={12} sm={4}
                        >
                            <Typography variant="h4" sx={styles.contactTitle}>
                                {t('footer_address')}
                            </Typography>
                            <Typography variant="body1">
                                Krzywaczka 550
                            </Typography>
                            <Typography variant="body1">
                                32-442 Krzywaczka
                            </Typography>
                            <Typography variant="body1">
                                Poland
                            </Typography>
                        </Grid>
                        <Grid container
                              sx={styles.contactGrid}
                              direction="column"
                              alignItems="center"
                              xs={12} sm={4}
                        >
                            <Typography variant="h4" sx={styles.contactTitle}>
                                {t('footer_phone')}
                            </Typography>
                            <Typography variant="body1">
                                +48 531-897-979
                            </Typography>
                        </Grid>
                        <Grid container xs={12} justifyContent="center">
                            <Typography variant="body1" sx={styles.copyright}>
                                © Proxi 2023. {t('copyright')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </Box>
    );
};

export default Footer;