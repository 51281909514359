import React from 'react'
import {Container, Theme, useTheme} from "@mui/material"

const createStyles = (theme: Theme) => ({
    contentWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        height: '100vh'
    }
});

const Content = (props: any) => {

    const styles = createStyles(useTheme());

    return (
        <Container sx={styles.contentWrapper}>
            {props.children}
        </Container>
    )
}

export default Content;