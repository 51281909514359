import React from 'react'
import Content from "../components/Content";
import {photo2} from "../assets";
import Teaser from "../components/Teaser";
import {useTranslation} from "react-i18next";


const HomePage = () => {

    const {t} = useTranslation();

    return (
        <Content>
            <Teaser img={photo2}
                    title={t('solutions_page_teaser_title')}
                    description={t('solutions_page_teaser_description')}
                    reverse
            />
        </Content>
    )
}

export default HomePage;