import React from 'react'
import Content from "../components/Content";
import Teaser from "../components/Teaser";
import {photo1} from "../assets";
import {useTranslation} from "react-i18next";


const HomePage = () => {

    const {t} = useTranslation();

    return (
        <Content>
            <Teaser img={photo1}
                    title={t('bi_page_teaser_title')}
                    description={t('bi_page_teaser_description')}
                    reverse
            />
        </Content>
    )
}

export default HomePage;