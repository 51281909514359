import React from 'react'
import Content from "../components/Content";
import Tile from "../components/Tile";
import {photo1, photo2, photo3, photo4, photo5} from "../assets";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import {routes} from "../commons/Routes";


const HomePage = () => {

    const navigate = useNavigate();

    return (
        <Content>
            <Tile img={photo3}
                  title={t('home_page_tile_bi_title')}
                  description={t('home_page_tile_bi_description')}
                  buttonContent={t('read_more')}
                  onButton={() => navigate(routes.bi)}

            />
            <Tile img={photo5}
                  title={t('home_page_tile_solutions_title')}
                  description={t('home_page_tile_solutions_description')}
                  buttonContent={t('read_more')}
                  onButton={() => navigate(routes.solutions)}
            />
        </Content>
    )
}

export default HomePage;