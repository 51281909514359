import {localeEn, localePl} from "./locales";
import i18n from "i18next";
import {defaultGlobalState} from "./state/GlobalState";
import {initReactI18next} from "react-i18next";

const resources = {
    en: {
        translation: localeEn,
    },
    pl: {
        translation: localePl,
    }
};

//i18N Initialization
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: defaultGlobalState.language.code,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
