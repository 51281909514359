import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {routes} from "./commons/Routes";
import HomePage from "./pages/HomePage";
import Layout from "./layout/Layout";
import BIPage from "./pages/BIPage";
import SolutionsPage from "./pages/SolutionsPage";
const AppRouter = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={<Layout/>}>
                    <Route path={routes.home} element={<HomePage/>}/>
                    <Route path={routes.bi} element={<BIPage/>}/>
                    <Route path={routes.solutions} element={<SolutionsPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;