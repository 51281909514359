import React from 'react'
import {Box, Button, Card, CardContent, CardMedia, Theme, Typography, useTheme} from "@mui/material";

const createStyles = (theme: Theme) => ({
    tile: {
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        }
    },
    tileImage: {
        height: '300px'
    },
    button: {

    }
});

const Tile = (props: any) => {

    const {title, subtitle, description, img, buttonContent, onButton} = props;

    const styles = createStyles(useTheme());

    return (
        <Card sx={[styles.tile]} square={true} elevation={0}>
            <CardMedia
                sx={[styles.tileImage]}
                component="img"
                image={img}
            />
            <CardContent>
                <Typography gutterBottom variant="body2" component="div">
                    {subtitle}
                </Typography>
                <Typography gutterBottom variant="h4" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary" align="justify">
                    {description}
                </Typography>
                {buttonContent && (
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="outlined" onClick={onButton}>{buttonContent}</Button>
                    </Box>
                )}
            </CardContent>
        </Card>
    );

};

export default Tile;
