import React from 'react'
import {Box, Fab, Theme, useTheme} from "@mui/material";
import {Outlet} from "react-router-dom";
import TopBar, {ScrollTop} from "./TopBar";
import {KeyboardArrowUp} from "@mui/icons-material";
import Footer from "./Footer";

const createStyles = (theme: Theme) => ({
    contentWrap: {
        paddingBottom: '9.5rem',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '40rem'
        }
    }
});


const Layout = (props: any) => {

    const styles = createStyles(useTheme());

    return (
        <React.Fragment>
            <TopBar/>
            <Box sx={{position: 'relative', minHeight: '100vh'}}>
                <Box sx={[styles.contentWrap]}>
                    <Outlet/>
                </Box>
                <ScrollTop {...props}>
                    <Fab color="secondary" size="medium" aria-label="scroll back to top">
                        <KeyboardArrowUp/>
                    </Fab>
                </ScrollTop>
                <Footer/>
            </Box>
        </React.Fragment>
    )
}

export default Layout;