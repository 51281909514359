import React from 'react'
import {Box, Button, CardMedia, Paper, Theme, Typography, useTheme} from "@mui/material";
import parse from 'html-react-parser';

const createStyles = (theme: Theme) => ({
    infoMediaSection: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
        minHeight: '600px',
        width: '100%'
    },
    image: {
        position: 'absolute',
        top: 0,
        [theme.breakpoints.up('md')]: {
            width: '80% !important',
            height: '600px',
        },
        [theme.breakpoints.down('md')]: {
            height: '400px',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
        objectFit: 'cover'
    },
    imageRow: {
        right: 0,
    },
    imageRowReverse: {
        left: 0
    },
    content: {
        position: 'absolute',
        backgroundColor: theme.palette.secondary.light + ' !important',
        [theme.breakpoints.up('md')]: {
            top: 0,
            bottom: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
            width: '50%',
            minHeight: '20%',
            maxHeight: '60%',
            overflow: 'hidden',
            height: 'auto',
            padding: theme.spacing(4)
        },
        [theme.breakpoints.down('md')]: {
            top: '45%',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
            padding: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
            position: 'static',
            top: '60%',
            width: '100%',
            boxSizing: 'border-box',
            padding: theme.spacing(2) + '!important'
        },
    },
    contentRow: {
        left: 0
    },
    contentRowReverse: {
        right: 0
    },
    title: {
        marginBottom: theme.spacing(3) + ' !important'
    },
    button: {
        marginTop: theme.spacing(3) + ' !important'
    }
});

interface TeaserProps {
    title: string
    description: string
    img: string
    reverse?: boolean
    buttonContent?: string
    onButtonClick?: any
}

const Teaser = (props: TeaserProps) => {

    const {title, description, img, reverse, buttonContent, onButtonClick} = props;

    const narrow = (value: string, limit: number) => {
        if (value.length > limit) {
            return value.substring(0, limit) + "...";
        }
        return value
    }

    const styles = createStyles(useTheme());

    return (
        <Box sx={[styles.infoMediaSection]}>
            <CardMedia
                sx={{...styles.image, ...(reverse ? styles.imageRowReverse : styles.imageRow)}}
                component="img"
                image={img}
            />
            <Paper sx={{...styles.content, ...(reverse ? styles.contentRowReverse : styles.contentRow)}}
                   elevation={0}
                   square>
                <Typography sx={[styles.title]}
                            variant="h1">
                    {title}
                </Typography>
                <Typography variant="body1" align="justify">
                    {parse(narrow(description, 600))}
                </Typography>
                {buttonContent && (
                    <Button sx={[styles.button]}
                            variant="outlined"
                            onClick={onButtonClick}>
                        {buttonContent}
                    </Button>
                )}
            </Paper>
        </Box>
    );

};

export default Teaser;
