import React, {createContext, useContext, useReducer} from 'react'
import {Language, languages} from "../commons/Languages";


export interface GlobalState {
    firstEntry: boolean,
    language: Language
}

export const defaultGlobalState: GlobalState = {
    firstEntry: true,
    language: languages.en,
};

const GlobalStateContext = createContext(defaultGlobalState);
const DispatchStateContext = createContext({});

export const GlobalStateProvider = ({children}: any) => {
    const [state, dispatch] = useReducer(
        (state: any, newValue: any) => ({...state, ...newValue}),
        defaultGlobalState
    );
    return (
        <GlobalStateContext.Provider value={state}>
            <DispatchStateContext.Provider value={dispatch}>
                {children}
            </DispatchStateContext.Provider>
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = (): [GlobalState, any] => [
    useContext(GlobalStateContext),
    useContext(DispatchStateContext)
];